import React from 'react';
import styled from 'styled-components';
import {
  device,
  OFFICE_ADDRESS,
  OFFICE_ADDRESS_KR,
  OFFICE_EMAIL_ADDRESS,
  OFFICE_FAX_NUMBER,
  OFFICE_TEL, PRESS_EMAIL_ADDRESS
} from "../utils/constants";

const Wrapper = styled.footer`
  width: 100%;
  padding: 20px;

  font-size: 14px;
  border-top: 1px solid #eee;
`;

const Info = styled.p`
  margin-bottom: 10px;
  line-height: 1.5;
  
  &.korean {
    font-size: 13px;
  }
`;

const Copy = styled.p`
  margin-top: 20px;
`;

export default () => (
  <Wrapper>

    <Info>{OFFICE_TEL}</Info>
    <Info>E. {OFFICE_EMAIL_ADDRESS}</Info>
    <Info className='korean'>A. {OFFICE_ADDRESS_KR}</Info>

    <Copy>© 2021 Norway Wood Design. All rights reserved.</Copy>
  </Wrapper>
);
