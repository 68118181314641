import React from 'react';
import styled from 'styled-components';
import { device } from 'utils/constants';
import { PressData } from './types';

interface PressCardListProps {
  cardList?: PressData[],
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const PressCard = styled.div`
  width: 100%;
  flex-shrink: 0;
  flex-basis: auto;
  margin-bottom: 32px;

  @media ${device.desktop} {
    width: calc(100% / 3);
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Description = styled.p`
  font-size: 16px;
  padding: 16px;
  text-align: right;
`;

const PressCardList:React.FC<PressCardListProps> = ({
  cardList,
}) => {
  const renderPressCardList = cardList?.map(card => (
    <PressCard>
      <Image src={card.img} alt={`press-card-${card.id}`} />
      <Description>
        {card.name}
      </Description>
    </PressCard>
  ));
  return (
    <Wrapper>
      {renderPressCardList}
    </Wrapper>
  );
};

export default PressCardList;
