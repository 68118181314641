import { NavigationListType } from "./types";

export const COMPANY_PATH = '/company';
export const PROJECT_PATH = '/project';
export const PRESS_PATH = '/press';
export const CONTACT_PATH = '/contact';
export const LOGIN_PATH = '/login';
export const ADMIN_PATH = '/admin';
export const ADMIN_PROJECT_PATH = `${ADMIN_PATH}/project`;
export const ADMIN_PRESS_PATH = `${ADMIN_PATH}/press`;

export const NORWAY_BLOG_LINK = 'https://blog.naver.com/norwaywood-';
export const NORWAY_INSTAGRAM_LINK = 'https://www.instagram.com/nwd_officially';

export const NAV_LIST: NavigationListType[] = [
  {
    label: 'company',
    path: COMPANY_PATH,
  },
  {
    label: 'project',
    path: PROJECT_PATH,
  },
  {
    label: 'press',
    path: PRESS_PATH,
  },
  {
    label: 'contact',
    path: CONTACT_PATH,
  },
];

export const SNS_LIST: NavigationListType[] = [
  {
    label: 'blog',
    path: NORWAY_BLOG_LINK,
  },
  {
    label: 'instagram',
    path: NORWAY_INSTAGRAM_LINK,
  },
]

const size = {
  desktop: '768px',
}

export const device = {
  desktop: `(min-width: ${size.desktop})`
}

export const OFFICE_ADDRESS_KR = '서울특별시 종로구 계동 2길 26 삼방빌딩 201호';
export const OFFICE_ADDRESS = '201, Sambang Bldg, 26, Gyedong 2-gil, Jongno-gu, Seoul, Republic of Korea';
export const OFFICE_TEL = 'T. +82. 02. 338. 5363';
export const OFFICE_FAX_NUMBER = 'F. +82. 02. 333. 5363';
export const OFFICE_EMAIL_ADDRESS = 'nwdinfo@naver.com';
export const PRESS_EMAIL_ADDRESS = 'nwdpress@naver.com';
