import { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

export const useAuthCheck = () => {
  let [hasAuth, setHasAuth] = useState(true);

  useEffect(() => {
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          setHasAuth(true);
        } else {
          setHasAuth(false);
        }
      });
    }, []);

  return hasAuth;
};
