import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import GlobalStyles from 'utils/global-styles';
import { NAV_LIST, SNS_LIST, device } from 'utils/constants';
import { iconClose, iconHamburger } from 'assets/icons';
import Footer from 'components/Footer';
import { imgLogoBlackWide } from 'assets/images';
import { PROJECT_PATH } from 'utils/constants';
import { NavigationListType } from 'utils/types';
import { upperCase as _upperCase } from 'lodash';

interface LayoutProps extends RouteComponentProps<any> {
  children: React.ReactNode,
}

const Wrapper = styled.div`
  width: 100%;
`;

const DesktopLayoutWrapper = styled.div`
  display: none;
  
  @media ${device.desktop} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 60px 24px;

    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 99;
  }
`;

const DesktopNavWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const DesktopNav = styled.nav<{ active?: boolean; isSocial?: boolean }>`
  width: 20%;
  font-size: 19px;
  border-left: ${({ active }) => active ? '3px solid black' : '1px solid black'};
  font-weight: ${({ active }) => active ? '600' : '300'};
  line-height: 1;

  a {
    line-height: 1;
    padding: 0 5px 2px 7px;
    
    &:hover {
      font-weight: 600;
      transition: all .3s ease-in-out;
    }
  }

  ${({ isSocial }) => isSocial ? `
    display: flex;
    flex-direction: column;
    a {
      margin-bottom: 4px;
      
      &:last-child {
        margin-bottom: 0px;
      }
  ` : ``}
`;

const DesktopLogoNav = styled(Link)`
  height: 45px;
  padding: 0 0 18px 18px;
  img {
    height: 100%;
  }
`;

const MobileNav = styled.nav<{ active?: boolean; }>`
  padding: 6px 6px 20px 12px;
  border-left: 1px solid #959595;

  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    font-size: 16px;
    font-weight: ${({ active }) => active ? '500' : '300'};
    transition: all .2s ease-in-out;
  }
`;

const MobileHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  z-index: 99;

  @media ${device.desktop} {
    display: none;
  }
`;


const MobileLogoLink = styled(Link)`
  height: 20px;

  img {
    height: 100%;
  }
`;

const Button = styled.button`
  height: 30px;
  background-color: inherit;
  img {
    height: 100%;
  }
`;

const LayoutRight = styled.div<{ isOpened: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 90px 20px 60px 20px;
  background-color: #fff;
  z-index: 9;

  left: ${({ isOpened }) => isOpened ? '0' : '100%' };
  transition: all .3s ease-in-out;

  button {
    position: fixed;
    top: 20px;
    right: ${({ isOpened }) => isOpened ? '20px' : '-100%' };
    background-color: #fff;
    transition: all .3s ease-in-out;
  }

  display: block;
  @media ${device.desktop} {
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 55px);

  padding-top: 70px;

  @media ${device.desktop} {
    padding-top: 152px;
  }
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isOpenedSideBar, toggleSideBar] = useState<boolean>(false);
  const location = useLocation();

  const renderDesktopNavList = NAV_LIST.map((nav: NavigationListType) => (
    <DesktopNav
      key={`navigation-${nav.label}`}
      active={location.pathname.includes(nav.path)}
    >
      <Link to={nav.path}>{_upperCase(nav.label)}</Link>
    </DesktopNav>
  ));

  const renderSocialLinkList = SNS_LIST.map((nav: NavigationListType) => (
    <a
      href={nav.path}
      target="_blank"
      rel="noopener noreferrer"
      key={`nav-${nav.path}`}
    >
      {_upperCase(nav.label)}
    </a>
  ));

  const handleToggleSideBar = () => {
    toggleSideBar(!isOpenedSideBar);
  };

  const renderMobileNavList = NAV_LIST.map((nav: NavigationListType) => (
    <MobileNav
      key={`navigation-${nav.label}`}
      active={nav.path === location.pathname}
      onClick={handleToggleSideBar}
    >
      <Link to={nav.path}>{_upperCase(nav.label)}</Link>
    </MobileNav>
  ));

  const renderMobileSocialLinkList = SNS_LIST.map((nav: NavigationListType) => (
    <MobileNav
      key={`navigation-${nav.label}`}
      active={nav.path === location.pathname}
      onClick={handleToggleSideBar}
    >
      <a href={nav.path} target="_blank" rel="noopener noreferrer">{_upperCase(nav.label)}</a>
    </MobileNav>
  ));

  return (
    <>
      <GlobalStyles />
      <Wrapper>
        {/* DESKTOP SECTION */}
        <DesktopLayoutWrapper>
          <DesktopNavWrapper>
            {renderDesktopNavList}
            <DesktopNav isSocial>
              {renderSocialLinkList}
            </DesktopNav>
          </DesktopNavWrapper>
          <DesktopLogoNav to={PROJECT_PATH}>
            <img src={imgLogoBlackWide} alt="logo-black" />
          </DesktopLogoNav>
        </DesktopLayoutWrapper>

        {/* MOBILE SECTION */}
        <MobileHeader>
          <MobileLogoLink to={PROJECT_PATH}>
            <img src={imgLogoBlackWide} alt="logo-black" />
          </MobileLogoLink>
          <Button type="button" onClick={handleToggleSideBar}>
            <img src={iconHamburger} alt="hamburger-menu-icon" />
          </Button>
        </MobileHeader>
        <LayoutRight isOpened={isOpenedSideBar}>
          <Button type="button" onClick={handleToggleSideBar}>
            <img src={iconClose} alt="menu-close-icon" />
          </Button>
          {renderMobileNavList}
          {renderMobileSocialLinkList}
        </LayoutRight>

        <Container>
          {children}
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
};

export default withRouter(Layout);
