import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'utils/constants';
import useAxios from 'axios-hooks';
import { startWithHttp } from 'utils/valdation';
import { GetProjectDetailResponse } from './types';
import { iconClose, iconCloseWhite } from 'assets/icons';
import ProjectInfo from './ProjectInfo';

type ProjectDetailProps = RouteComponentProps<{ id: string }>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const ImageSection = styled.section`
  width: 100%;

  @media ${device.desktop} {
    width: 50%;
  }
`;

const Image = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

const ProjectInfoSection = styled.div<{ isVisible: boolean }>`
  padding: 48px 48px 24px 24px;
  position: fixed;
  right: 0;
  background-color: #fff;

  top: ${({ isVisible }) => isVisible ? '152px' : '-100%'};
  transition: 0.3s all;

  button {
    position: fixed;
    right: 8px;
    top: ${({ isVisible }) => isVisible ? '160px' : '-100%'};
    transition: 0.3s all;
    width: 32px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0);

    img {
      width: 100%;
    }
  }
`;

const MobileProjectInfoSection = styled.div`
  padding: 36px 12px 64px;
`;

const Button = styled.button`
  padding: 12px;
  width: 52px;
  position: fixed;
  top: 152px;
  right: 0;
  background-color: rgba(0, 0, 0, 0);

  transform: rotate(90deg);

  img {
    width: 100%;
    transform: rotate(45deg);
  }
`;

const ProjectDetail: React.FC<ProjectDetailProps> = ({ match }) => {
  const [{ data }] = useAxios<GetProjectDetailResponse>({
    method: 'get',
    url: '/project',
    params: {
      id: match.params.id,
    },
  });

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  const renderImageNode = () => (
    data?.project?.imgs.map((imgUrl, index) => {
      return (
        imgUrl && (
          <Image key={`project-image-${index}`}>
            <img src={imgUrl} alt={`project-${index}`} />
          </Image>
        )
      )
    })
  );

  const renderMobileImageNode = (isEven: boolean) =>
    data?.project?.imgs
      .filter((imgUrl) => startWithHttp(imgUrl))
      .filter((_, index) => isEven ? index % 2 === 0 : index % 2 === 1)
      .map((imgUrl, index) => (
        <Image key={`project-image-${index}`}>
          <img src={imgUrl} alt={`project-${index}`} />
        </Image>
      ));

  const [isVisibleInfo, setIsVisibleInfo] = useState<boolean>(false);

  const toggleInfoSection = () => {
    setIsVisibleInfo(isVisible => !isVisible);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      {
        isMobile ? (
          <ImageSection>
            {renderImageNode()}
          </ImageSection>
        ) : (
          <>
            <ImageSection>
              {renderMobileImageNode(true)}
            </ImageSection>
            <ImageSection>
              {renderMobileImageNode(false)}
            </ImageSection>
          </>
        )
      }

      {
        isMobile ? (
          <MobileProjectInfoSection>
            {data?.project && <ProjectInfo project={data.project} />}
          </MobileProjectInfoSection>
        ) : (
          <>
            <ProjectInfoSection isVisible={isVisibleInfo}>
              <button type='button' onClick={toggleInfoSection}>
                <img src={iconClose} alt='close-icon' />
              </button>
              {data?.project && <ProjectInfo project={data.project} />}
            </ProjectInfoSection>
            {
              !isVisibleInfo && (
                <Button
                  type='button'
                  onClick={toggleInfoSection}
                >
                  <img src={iconCloseWhite} alt='close-icon' />
                </Button>
              )
            }
          </>
        )
      }
    </Wrapper>
  )
};

export default ProjectDetail;
