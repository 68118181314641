import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import Nav from './Nav';
import ProjectList from './ProjectList';
import PressList from './PressList';
import { MENU_LIST } from './config';
import { useAuthCheck } from 'utils/useAuthCheck';
import { MenuName } from './types';

interface AdminProps {
  menu?: string
}

type ListView = {
  [key in MenuName]: JSX.Element
}

const listView: ListView= {
  [MenuName.Project]: <ProjectList />,
  [MenuName.Press]: <PressList />
};

const Admin: React.FC<AdminProps> = ({ menu = MenuName.Project }) => {
  const [activeMenu, setActiveMenu] = useState(MENU_LIST[menu]);
  const hasAuth = useAuthCheck();
  let history = useHistory();

  useEffect(() => {
    if (!hasAuth) history.push('/login');
  }, [hasAuth]);

  return (
    <Wrapper className="admin-page">
      <Nav
        activeMenu={activeMenu}
        handleClickMenu={setActiveMenu}
      />
      {listView[activeMenu.name]}
    </Wrapper>
  )
};

export default Admin;

const Wrapper = styled.div`
  display: flex;
  max-width: 1200px;
  padding: 50px;
`;
