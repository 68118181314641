import React from 'react';
import styled from 'styled-components';

import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';

interface PaginationProps {
  currentPage: number,
  totalCount: number,
  handleClickPageItem?: (page: number) => void,
  toPage?: (page: number) => string
}

const PaginationList: React.FC<PaginationProps> = ({
  currentPage,
  totalCount,
  handleClickPageItem = () => {},
  toPage
}) => {

  return (
    <Wrapper>
      {
        Array.from({ length: Math.ceil(totalCount / 10) }, (_, index) => (
          <PageItem
            active={currentPage === (index + 1)}
            href={toPage && toPage(index + 1)}
            key={`page-${index + 1}`}
            onClick={() => handleClickPageItem(index+1)}
          >
            {index + 1}
          </PageItem>
        ))
      }
    </Wrapper>
  );
}

export default PaginationList;

const Wrapper = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  a.page-link {
    color: #555555 !important;
  };

  .page-item.active .page-link {
    background-color: #555555;
    border-color: #555555;
  }
`;
