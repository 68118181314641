import React from 'react';
import styled from 'styled-components';
import qs from 'qs';

import Pagination from 'react-bootstrap/Pagination';

interface PaginationProps {
  currentPage: number,
  totalCount: number,
}

const Wrapper = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin: 20px;
  padding: 40px 0;
  
  .pagination {
    flex-wrap: wrap;
  }

  .page-link {
    min-width: 35px;
    padding: 0.5rem 0.75rem;
    margin-left: -1px !important;
    border: 1px solid #dee2e6;
    color: #555555;
    
    &:focus {
      box-shadow: none;
    };
  };

  .page-item.active .page-link {
    background-color: #555555;
    border-color: #555555;
    color: white !important;
  }
`;

const PaginationList: React.FC<PaginationProps> = ({
  currentPage,
  totalCount,
}) => {
  const makeTargetLink = (n: number): string =>
    `/project?${qs.stringify({ page: n })}`;

  return (
    <Wrapper>
      <Pagination>
      {
        Array.from({ length: Math.ceil(totalCount / 4) }, (_, index) => (
          <Pagination.Item
            active={currentPage === (index + 1)}
            href={makeTargetLink(index + 1)}
            key={`page-${index + 1}`}
          >
            {index + 1}
          </Pagination.Item>
        ))
      }
      </Pagination>
    </Wrapper>
  );
}

export default PaginationList;
