import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { configureAxiosHook } from 'api';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import './config/firebase';
import './custom-bootstrap.scss';

// initialize axios hook config
configureAxiosHook();

ReactDOM.render(
  <StrictMode>
    <Routes />
  </StrictMode>,
  document.getElementById('root')
);
