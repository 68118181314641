import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  OFFICE_ADDRESS,
  OFFICE_ADDRESS_KR,
  OFFICE_EMAIL_ADDRESS,
  OFFICE_FAX_NUMBER,
  OFFICE_TEL, PRESS_EMAIL_ADDRESS
} from 'utils/constants';
import { device } from 'utils/constants';
import Map from 'components/Map';

const Wrapper = styled.div`
  width: 100%;
  padding: 0 20px 40px;

  @media ${device.desktop} {
    padding: 0 20px 100px;
  }
`;

const Section = styled.section`
  display: block;
  margin-top: 50px;
  margin-bottom: 20px;

  @media ${device.desktop} {
    display: flex;
    margin-bottom: 40px;
  }
`;

const LeftWrapper = styled.div`
  width: 100%;
  @media ${device.desktop} {
    padding-right: 28px;
    width: 50%;
  }
`;

const InfoWrapper = styled.div`
  margin-bottom: 50px;

  @media ${device.desktop} {
    margin-bottom: 100px;
  }
`;

const MapSection = styled.div`
  width: 100%;
  height: 60vw;
  margin-bottom: 36px;

  @media ${device.desktop} {
    margin-bottom: 0;
    height: 30vw;
  }
`;

const Info = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 1.5;

  span {
    display: inline-block;
    margin-right: 12px;
  }

  @media ${device.desktop} {
    font-size: 19px;
    margin-bottom: 20px;
  }
`;

const Mail = styled.a`
  display: inline-block;
  margin-bottom: 20px;
  font-size: 20px;
  text-decoration: underline;

  &:hover {
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }

  @media ${device.desktop} {
    font-size: 27px;
  }
`;

const ContactInfo = styled.p`
  font-size: 14px;
  margin-bottom: 10px;

  @media ${device.desktop} {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const ContactInfoDetail = styled.p`
  font-size: 10px;
`;

const RightWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media ${device.desktop} {
    width: 50%;
  }
`;

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Wrapper>
      {/* Map Section 캡쳐 -> 실제 map sdk 로 변경 */}
      <Section>
        <LeftWrapper>
          <MapSection>
            <Map />
          </MapSection>
        </LeftWrapper>

        <RightWrapper>

          <InfoWrapper>
            <Mail href={`mailto:${OFFICE_EMAIL_ADDRESS}`}>
              {OFFICE_EMAIL_ADDRESS}
            </Mail>
            <ContactInfo className='korean'>
              프로젝트 문의시 아래의 내용을 기재해서 보내주세요
            </ContactInfo>
            <ContactInfoDetail className='korean'>
              [클라이언트 성함, 전화번호 / 프로젝트 위치, 일정, 공사내용]
            </ContactInfoDetail>
          </InfoWrapper>

          <InfoWrapper>
            <Info className='korean'>{OFFICE_ADDRESS_KR}</Info>
            <Info>{OFFICE_ADDRESS}</Info>
            <Info>{OFFICE_TEL}</Info>
            <Info>{OFFICE_FAX_NUMBER}</Info>
            <Info>E. {OFFICE_EMAIL_ADDRESS}</Info>
            <Info>P. {PRESS_EMAIL_ADDRESS}</Info>
          </InfoWrapper>
        </RightWrapper>
      </Section>
    </Wrapper>
  );
};
