import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { BASE_URL_API } from 'api';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

type PressProps = RouteComponentProps<{ id: string }>;

const Press: React.FC<PressProps> = ({ match }) => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [inputData, setInputData] = useState({
    name: '',
    img: ''
  });

  useEffect(() => {
    if (match.params.id) {
      (async() => {
        const { data } = await axios.get(`${BASE_URL_API}/press?id=${match.params.id}`);

        if (data.press.name) {
          setInputData(data.press);
        } else {
          history.push('/admin');
        }
      })();
    }
  }, []);

  const save = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData();

    formData.append('name', inputData.name);
    match.params.id && formData.append('id', match.params.id);

    file && formData.append(
      `img`,
      file,
    );

    (async() => {
      setIsLoading(true);
      const result = await axios.post(
        `${BASE_URL_API}/press`,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );

      setIsLoading(false);
      if (result.data.id) {
        alert('저장 성공');
      } else {
        alert('저장 실패');
      }

      history.push('/admin');
    })();
  };

  const deletePress = () => {
    (async() => {

      setIsLoading(true);
      const result = await axios.delete(`${BASE_URL_API}/press?id=${match.params.id}`);
      setIsLoading(false);

      if (result.data.id) {
        alert('삭제 성공');
      } else {
        alert('삭제 실패');
      }

      history.push('/admin');
    })();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pressFile = e.target.files;

    if (pressFile && pressFile.length > 0) {
      setFile(pressFile[0]);
    }
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value
    })
  };

  return (
    <Wrapper className="admin-upload-page korean">
      {isLoading && <Loading animation="border"/>}
      <Card className="card-wrap">
        <Card.Header>
          Press Information
        </Card.Header>
        <Card.Body>
          <Form onSubmit={save}>
            <Button variant="primary" type="submit">
              Save
            </Button>

            <Row>
              <Col>
                <Form.Group controlId="uploadForm.Name">
                  <Form.Label>Press Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name 입력"
                    name="name"
                    value={inputData.name}
                    onChange={handleValueChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>대표사진</Form.Label>
              <Form.Control type="file"
                onChange={handleFileChange}
                required
              />
            </Form.Group>
            {match.params.id && <img width={300} src={inputData.img}/>}
          </Form>
        </Card.Body>
      </Card>

      {match.params.id && (
        <Button variant="secondary" onClick={deletePress}>
          삭제
        </Button>
      )}
    </Wrapper>
  )
};

export default Press;

const Wrapper = styled.div`
  max-width: 1200px;
  padding: 50px;
  font-style: initial;
  
  .card-wrap {
    margin-bottom: 50px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  img {
    display: block;
  }
  
  button {
    margin-bottom: 30px; 
  }
`;

const Loading = styled(Spinner)`
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
`;
