import { ADMIN_PRESS_PATH, ADMIN_PROJECT_PATH } from 'utils/constants';
import { MenuList, MenuName } from './types';

export const MENU_LIST: MenuList = {
  projects: {
    name: MenuName.Project,
    api: '/projects',
    path: ADMIN_PROJECT_PATH
  },
  press: {
    name: MenuName.Press,
    api: '/press',
    path: ADMIN_PRESS_PATH
  },
};
