import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCFcRcs-en68gYvWto717Vhr81wnclCF80",
  authDomain: "norway-92d0f.firebaseapp.com",
  databaseURL: "https://norway-92d0f.firebaseio.com",
  projectId: "norway-92d0f",
  storageBucket: "norway-92d0f.appspot.com",
  messagingSenderId: "1013933830736",
  appId: "1:1013933830736:web:1c168f45c0dedfd66f9755",
  measurementId: "G-HYD1BP658S"
};

firebase.initializeApp(firebaseConfig);
