import React from 'react';
import styled from 'styled-components';
import { xIcon } from 'assets/images';

interface PreviewProps {
  src: string,
  handleClick?: () => void,
}

const Wrapper = styled.div`
  position: relative;
  width: 300px;
`;

const Img = styled.img`
  width: 100%;
`;

const XIcon = styled.img`
  position: absolute;
  top: -5px;
  right: -5px;
  
  width: 20px;
  height: 20px;
  
  cursor: pointer;
  opacity: 0.7;
`;

const Preview:React.FC<PreviewProps> = ({ src, handleClick }) => {
  if (!src) return null;

  return (
    <Wrapper>
      <Img width={300} src={src}/>
      <XIcon src={xIcon} onClick={handleClick} />
    </Wrapper>
  );
};

export default Preview;
