import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/auth";

import { googleIcon } from 'assets/icons';

const Login: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let history = useHistory();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  const handleLogin = async () => {
    if (isLoggedIn) return;

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL) // 로그인 상태 유지
      .then(() => {
        const provider = new firebase.auth.GoogleAuthProvider(); // google provider 생성
        return firebase.auth().signInWithPopup(provider); // google 로그인 실행
      })
      .then(result => {
        const credential: any = result.credential;

        if (credential && credential.idToken) {
          localStorage.setItem("token", credential.idToken);
        }

        setIsLoggedIn(true);

        history.push("/admin");
      });
  };

  const toAdmin = () => {
    history.push("/admin");
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("userName");
        setIsLoggedIn(true);

        alert("로그아웃 되었습니다 :)");
      });
  };

  return (
    <LoginContainer>
      {isLoggedIn ? (
        <div>
          <AdminButton onClick={toAdmin}>
            Go To Admin Page
          </AdminButton>
          <LogoutButton onClick={handleLogout}>
            <span>Logout</span>
          </LogoutButton>
        </div>
      ) : (
        <Button onClick={handleLogin}>
          <img alt="icon" src={googleIcon}/>
          <span>Login with Google</span>
        </Button>
      )}
    </LoginContainer>
  );
}

export default Login;

const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #eef5f6;
`;

const Button = styled.div`
  margin-bottom: 20px;
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 15px 30px;
  color: #555;
  cursor: pointer;

  img {
    height: 30px;
    width: 30px;
    margin-right:10px;
  }
`;

const AdminButton = styled(Button)`
  font-weight: bold;
`;

const LogoutButton = styled(Button)`
  background-color: #ececec;
`;
