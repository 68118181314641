import React from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { ProjectData } from "pages/Project/types";
import { PressData } from "pages/Press/types";

interface ListTableProps {
  data: (ProjectData | PressData)[];
  detailLink: (id: string) => string;
}

const ListTable: React.FC<ListTableProps> = ({ data, detailLink }) => {
  return (
    <Table striped bordered hover>
      <tbody>
      <tr>
        <th>Project Name</th>
      </tr>
      {data.map((item, index) => (
        <tr key={item.id}>
          <td>
            <Link to={{ pathname: detailLink(item.id)}}>
              {item.name}
            </Link>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  );
};

export default ListTable;
