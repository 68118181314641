import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { BASE_URL_API } from 'api';
import { ProjectDetailInfo } from 'pages/ProjectDetail/types';
import Preview from '../components/Preview';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

type InputDateType = Omit<ProjectDetailInfo, 'id'>;
type ProjectProps = RouteComponentProps<{ id: string }>;

const Project: React.FC<ProjectProps> = ({ match }) => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [inputData, setInputData] = useState<InputDateType>({
    name: '',
    area: '',
    location: '',
    imgs: [],
    type: ''
  });

  useEffect(() => {
    if (match.params.id) {
      (async() => {
        const { data } = await axios.get(`${BASE_URL_API}/project?id=${match.params.id}`);

        if (data.project.name) {
          setInputData(data.project);
        } else {
          history.push('/admin');
        }
      })();
    }
  }, []);

  const save = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData();

    if (match.params.id) {
      formData.append('id', match.params.id);
      formData.append('imgs', JSON.stringify(inputData.imgs));
    }

    formData.append('name', inputData.name);
    formData.append('type', inputData.type);
    formData.append('location', inputData.location);
    formData.append('area', inputData.area);
    formData.append('client', inputData.client || '');

    files.forEach((file, idx) => {
      file && formData.append(
        `img_${idx}`,
        file,
      );
    });

    (async() => {
        setIsLoading(true);
        const result = await axios.post(
          `${BASE_URL_API}/project`,
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        setIsLoading(false);

        if (result.data.id) {
          alert('저장 성공');
        } else {
          alert('저장 실패');
        }

        history.push('/admin');
      }
    )();
  };

  const deleteProject = () => {
    (async() => {

      setIsLoading(true);
      const result = await axios.delete(
        `${BASE_URL_API}/project?id=${match.params.id}`
      );
      setIsLoading(false);

      if (result.data.id) {
        alert('삭제 성공');
      } else {
        alert('삭제 실패');
      }

      history.push('/admin');
    })();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files;
    const idx = e.target.dataset.idx;
    let filesCopy = [...files];

    if (!idx) return;

    if (file && file.length > 0) {
      filesCopy[Number(idx)] = file[0];
      setFiles(filesCopy);
    }
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value
    })
  };

  const deleteImg = (idx: number) => {
    if (inputData.imgs[idx]) {
      let copyImgs = [...inputData.imgs];
      copyImgs[idx] = '';

      setInputData({
        ...inputData,
        imgs: copyImgs
      })
    }
  };

  return (
    <Wrapper className="admin-upload-page korean">
      {isLoading && <Loading animation="border"/>}

      {match.params.id && (
        <Link to={`/project/${match.params.id}`}>
          <Button variant="secondary" size="sm">상세페이지 확인</Button>{' '}
        </Link>
      )}

      <Card className="card-wrap">
        <Card.Header>
          Project Information
        </Card.Header>
        <Card.Body>
          <Form onSubmit={save}>
            <Button variant="primary" type="submit">
              Save
            </Button>

            <Row>
              <Col>
                <Form.Group controlId="uploadForm.Name">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name 입력"
                    name="name"
                    value={inputData.name}
                    onChange={handleValueChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="uploadForm.Client">
                  <Form.Label>Client</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Client 입력"
                    name="client"
                    value={inputData.client}
                    onChange={handleValueChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="uploadForm.Type">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type 입력"
                    name="type"
                    value={inputData.type}
                    onChange={handleValueChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="uploadForm.Area">
                  <Form.Label>Area</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Area 입력"
                    name="area"
                    value={inputData.area}
                    onChange={handleValueChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="uploadForm.Location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Location 입력"
                name="location"
                value={inputData.location}
                onChange={handleValueChange}
                required
              />
            </Form.Group>

            <Table>
              <tbody>
              <tr>
                <td>
                  <Form.Group>
                    <Form.Label>대표사진</Form.Label>
                    <Form.Control type="file"
                      data-idx={0}
                      onChange={handleFileChange}
                      required={!match.params.id}
                    />
                  </Form.Group>
                  {match.params.id && <Preview src={inputData.imgs[0]} handleClick={() => deleteImg(0)}/>}
                </td>
                <td>
                  <Form.Group>
                    <Form.Label>사진2</Form.Label>
                    <Form.Control type="file"
                      data-idx={1}
                      onChange={handleFileChange}
                      required={!match.params.id}
                    />
                  </Form.Group>
                  {match.params.id && <Preview src={inputData.imgs[1]} handleClick={() => deleteImg(1)}/>}
                </td>
              </tr>
              {[...Array(9)].map((a, idx) => (
                <tr key={idx}>
                  <td>
                    <Form.Group>
                      <Form.Label>사진 {idx * 2 + 3}</Form.Label>
                      <Form.Control type="file" data-idx={idx * 2 + 2} onChange={handleFileChange}/>
                    </Form.Group>
                    {match.params.id && <Preview src={inputData.imgs[idx * 2 + 2]} handleClick={() => deleteImg(idx * 2 + 2)}/>}
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Label>사진 {idx * 2 + 4}</Form.Label>
                      <Form.Control type="file" data-idx={idx * 2 + 3} onChange={handleFileChange}/>
                    </Form.Group>
                    {match.params.id && <Preview src={inputData.imgs[idx * 2 + 3]} handleClick={() => deleteImg(idx * 2 + 3)}/>}
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          </Form>
        </Card.Body>
      </Card>

      {match.params.id && (
        <Button variant="secondary" onClick={deleteProject}>
          삭제
        </Button>
      )}
    </Wrapper>
  )
};

export default Project;

const Wrapper = styled.div
  `
    max-width: 1200px;
    padding: 50px;
    font-style: initial;

    .card-wrap {
    margin-bottom: 50px;
    }

    .form-group {
    margin-bottom: 20px;
    }

    img {
    display: block;
    }

    button {
    margin-bottom: 30px;
    }
    `
;

const Table = styled.table
  `
    width: 100%;

    td {
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;
    }
    `
;

const Loading = styled(Spinner)
  `
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    `
;
