import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

declare global {
  interface Window {
    kakao: {
      maps: any;
    };
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Map: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isDrawn, setisDrawn] = useState<boolean>(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const drawKakaoMap = useCallback(() => {
    if (window?.kakao === undefined || window?.kakao?.maps === undefined) {
      throw new Error('Kakao Map API 를 정상적으로 가져오지 못했습니다.');
    }

    window?.kakao?.maps?.load(() => {
      let container = document.getElementById('map');
      let options = {
        center: new window.kakao.maps.LatLng(37.5794861, 126.987582),
        level: isMobile ? 5 : 4,
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const map = new window.kakao.maps.Map(container, options);

      let markerPosition = new window.kakao.maps.LatLng(37.5794861, 126.987582);
      let marker = new window.kakao.maps.Marker({
        position: markerPosition,
      });

      marker.setMap(map);
    });
  }, [isMobile]);

  useEffect(() => {
    if (isDrawn === false) {
      drawKakaoMap();
      setisDrawn(true);
    }
  }, [isDrawn, drawKakaoMap]);

  return (
    <Wrapper id='map' />
  );
};

export default Map;
