import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { ADMIN_PRESS_PATH } from 'utils/constants';
import useAxios from 'axios-hooks';
import { GetPressResponse } from '../Press/types';
import DataTable from './components/DataTable';

const PressList: React.FC = () => {
  const [{ data }] = useAxios<GetPressResponse>({
    url: '/press',
  });

  return (
    <Wrapper>
      <Link
        className="upload-btn"
        to={{
          pathname: ADMIN_PRESS_PATH,
        }}
      >
        <Button variant="info">
          Add Press
        </Button>
      </Link>

      {data && (
        <DataTable
          data={data.press}
          detailLink={id => `${ADMIN_PRESS_PATH}/${id}`}
        />
      )}
    </Wrapper>
  );
};

export default PressList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .upload-btn {
    align-self: flex-end;
    margin-bottom: 10px;
  }
`;
