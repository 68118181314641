import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'utils/constants';
import { ProjectData } from './types';

interface ProjectCardListProps {
  projects?: ProjectData[],
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const ProjectCard = styled(Link)`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-bottom: 12px;
  width: 50%;
  display: inline-block;
  cursor: pointer;
  
  &:focus {
    border: none;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media ${device.desktop} {
    margin-bottom: 32px;
  }
`;

const ImageWrapper = styled.div`
  min-height: 75vw;
  height: 75vw;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Image = styled.img`
  height: 100%;
  transition: all 0.3s ease-in-out;
  object-fit: cover;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 14px;
  text-align: left;
  padding: 6px 2px;

  @media ${device.desktop} {
    font-size: 16px;
    padding: 16px;
  }
`;

const ProjectCardList:React.FC<ProjectCardListProps> = ({ projects }) => {
  const renderProjectCardList = () => projects?.map((project, index) => (
    <ProjectCard to={`/project/${project.id}`} key={`project-card-${index}`}>
      <ImageWrapper>
        <Image src={project.thumbnail} alt={`project-card-${index}`} />
      </ImageWrapper>
      <Title>
        {project.name}
      </Title>
    </ProjectCard>
  ));

  return (
    <Wrapper>
      {renderProjectCardList()}
    </Wrapper>
  )
};

export default ProjectCardList;
