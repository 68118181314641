import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { ADMIN_PROJECT_PATH } from 'utils/constants';
import useAxios from 'axios-hooks';
import Pagination from 'components/Pagination';
import { GetProjectsResponse } from '../Project/types';
import Table from './components/DataTable';

const ProjectList: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [{ data }] = useAxios<GetProjectsResponse>({
    url: `/projects?page=${currentPage}`,
  });

  return (
    <Wrapper>
      {data && (
        <>
          <Link
            className="upload-btn"
            to={{ pathname: ADMIN_PROJECT_PATH }}
          >
            <Button variant="info">
              Add Project
            </Button>
          </Link>

          <Table
            data={data.projects}
            detailLink={id => `${ADMIN_PROJECT_PATH}/${id}`}
          />

          <Pagination
            currentPage={currentPage}
            totalCount={data.total}
            handleClickPageItem={page => setCurrentPage(page)}
          />
        </>
      )}
    </Wrapper>
  );
};

export default ProjectList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .upload-btn {
    align-self: flex-end;
    margin-bottom: 10px;
  }
`;
