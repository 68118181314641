import Axios from 'axios';
import { configure } from 'axios-hooks';

export const BASE_URL_API = 'https://us-central1-norway-92d0f.cloudfunctions.net/api';

export function configureAxiosHook() {
  const axios = Axios.create({
    baseURL: BASE_URL_API,
  });

  configure({ axios });
}
