import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Company from 'pages/Company';
import Contact from 'pages/Contact';
import Press from 'pages/Press';
import Project from 'pages/Project';
import ProjectDetail from 'pages/ProjectDetail';
import Login from 'pages/Login';
import Admin from 'pages/Admin';
import ProjectUpload from 'pages/Admin/Upload/Project';
import PressUpload from 'pages/Admin/Upload/Press';
import Layout from 'components/Layout';
import {
  CONTACT_PATH,
  PROJECT_PATH,
  PRESS_PATH,
  ADMIN_PATH,
  COMPANY_PATH,
  ADMIN_PROJECT_PATH,
  ADMIN_PRESS_PATH,
  LOGIN_PATH
} from 'utils/constants';

export default () => (
  <Router>
    <Switch>
      <Layout>
        <Route exact path='/'>
          <Redirect to={PROJECT_PATH} />
        </Route>
        <Route path={COMPANY_PATH} component={Company} />
        <Route path={PROJECT_PATH} exact component={Project} />
        <Route path={`${PROJECT_PATH}/:id`} component={ProjectDetail} />
        <Route path={CONTACT_PATH} component={Contact} />
        <Route path={PRESS_PATH} component={Press} />
        <Route path={LOGIN_PATH} exact component={Login} />
        <Route path={ADMIN_PATH} exact component={Admin} />
        <Route path={ADMIN_PROJECT_PATH} exact component={ProjectUpload} />
        <Route path={ADMIN_PRESS_PATH} exact component={PressUpload} />
        <Route path={`${ADMIN_PROJECT_PATH}/:id`} exact component={ProjectUpload} />
        <Route path={`${ADMIN_PRESS_PATH}/:id`} exact component={PressUpload} />
      </Layout>
      <Redirect from='*' to={PROJECT_PATH} />
    </Switch>
  </Router>
);
