import React, { useEffect } from 'react';
import styled from 'styled-components';
import useAxios from 'axios-hooks';

import { GetPressResponse } from './types';
import PressCardList from './PressCardList';

const Wrapper = styled.div``;

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [{ data }] = useAxios<GetPressResponse>('/press');

  return (
    <Wrapper>
      <PressCardList cardList={data?.press} />
    </Wrapper>
  );
};
