import React, { useEffect } from 'react';
import styled from 'styled-components';
import useAxios from 'axios-hooks';
import { Redirect, useLocation } from 'react-router-dom';
import qs from 'qs';

import { GetProjectsResponse } from './types';
import ProjectCardList from './ProjectCardList';
import PaginationList from './PaginationList';

const Wrapper = styled.div``;

const Project: React.FC = () => {
  const location = useLocation();
  const { page } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [{ data }] = useAxios<GetProjectsResponse>(
    {
      method: 'GET',
      url: '/projects',
      params: {
        page,
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!location.search) {
    return <Redirect to={`/project?page=1`} />
  }

  return (
    <Wrapper className='proejct-page'>
      <ProjectCardList projects={data?.projects || []} />

      <PaginationList currentPage={Number(page)} totalCount={data?.total || 0} />
    </Wrapper>
  )
};

export default Project;
