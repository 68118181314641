import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ADMIN_PATH } from 'utils/constants';
import { MENU_LIST } from './config';
import { Menu } from './types';

interface NavProps {
  activeMenu: Menu,
  handleClickMenu: (menu: Menu) => void
}

const Nav: React.FC<NavProps> = ({ activeMenu, handleClickMenu }) => {
  return (
      <MenuWrapper>
        {Object.entries(MENU_LIST).map(([key, menu]) => (
          <MenuItem
            key={key}
            active={activeMenu.name === menu.name}
            onClick={() => handleClickMenu(menu)}
          >
            <Link to={{
              pathname: ADMIN_PATH,
              state: { menu: menu.name }
            }}>
            {menu.name}
            </Link>
          </MenuItem>
        ))}
      </MenuWrapper>
  )
};

export default Nav;

const MenuWrapper = styled.ul`
  width: 130px;
  margin-right: 50px;
`;

const MenuItem = styled.li<{ active: boolean }>`
  padding: 10px;
  font-weight: ${({ active }) => active ? '500' : '300'};
  cursor: pointer;
`;
