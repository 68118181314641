export interface MenuList {
  [key: string]: Menu;
}

export enum MenuName {
  Project = 'projects',
  Press = 'press'
}

export interface Menu {
  name: MenuName.Project | MenuName.Press;
  api: string;
  path: string;
}
