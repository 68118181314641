import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  imgSponsor01,
  imgSponsor02,
  imgSponsor03,
  imgSponsor04,
  imgSponsor05,
  imgSponsor06,
  imgAbout,
  logoStory
} from 'assets/images';
import { device } from 'utils/constants';

const Wrapper = styled.div`
  padding: 0 20px;
`;

const Thumbnail = styled.div`
  max-width: 500px;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
`;

const AboutSection = styled.section`
  padding-top: 16px;
  margin-bottom: 50px;
  
  h3 {
    font-size: 16px;
    margin-bottom: 4px;
  }

  @media ${device.desktop} {
    padding-top: 0;
  }
`;

const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  p {
    margin-top: 20px;
    margin-bottom: 30px;

    font-size: 13px;
    line-height: 25px;
  }

  @media ${device.desktop} {
    flex-direction: row;

    p {
      margin-bottom: 50px;

      font-size: 15px;
      line-height: 35px;
    }
  }
`;

const SmallTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 600;

  @media ${device.desktop} {
    font-size: 20px;
    margin-bottom: 40px;
  }
`;

const PartnerSection = styled.section`
  width: 100%;
  padding: 20px 0;
`;

const LogoSection = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px;

  @media ${device.desktop} {
    justify-content: left;
  }
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const LogoStorySection = styled.div`
  margin-bottom: 50px;

  img {
    width: 100%;
  }
`;

const PartnerImage = styled.img`
  height: 30px;

  @media ${device.desktop} {
    height: 45px;
  }
`;

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <AboutSection>
        <AboutContent>
          <Thumbnail>
            <Image src={imgAbout} alt="company" />
          </Thumbnail>

          <div>
            <p className='korean'>
              노르웨이숲 디자인은 2011년 7월에 김성수&김은선이 함께 설립했다.<br/>
              북유럽 라이프 스타일의 간결하며 실용적인 디자인 철학을 한국이 가진 환경을 기반으로 재해석하는 일을 한다.<br/>
              이상적 공간을 찾고 다양한 방식으로 디자인하고 직접 실행하는 전반적인 공간 디자인 서비스를 제공한다.<br/>
              모든 공간이 중요하다고 배웠으므로 공간을 대하는 자세와 밀도는 늘 동일하게 유지하려고 노력한다.<br/>
              우리의 비전은 한국의 디자인 환경에 도움이 되는 것, 우리가 속한 환경을 사랑하고 존중하며 함께 성장하는 것이다.
            </p>

            <p>
              <Underline>NORWAYWOOD DESIGN</Underline> was founded in July 2011 by Sung-Su Kim and Eun-Sun Kim.<br/>
              I reinterpret the concise and practical design philosophy of the northern European lifestyle based on the Korean environment.<br/>
              We provide overall space design services that find the ideal space, design it in various ways, and execute it directly.<br/>
              Since I learned that all spaces are important, I try to keep the attitude and density of space always the same.<br/>
              Our vision is to help the design environment in Korea, love and respect the environment we belong to, and grow together.
            </p>
          </div>
        </AboutContent>
      </AboutSection>

      <LogoStorySection>
        <img src={logoStory} alt="logo" />
      </LogoStorySection>

      <PartnerSection>
        <SmallTitle>WE ARE TOGETHER</SmallTitle>
        <LogoSection>
          <PartnerImage src={imgSponsor05} alt="sponsor-logo-01" />
          <PartnerImage src={imgSponsor06} alt="sponsor-logo-03" />
          <PartnerImage src={imgSponsor03} alt="sponsor-logo-02" />
          <PartnerImage src={imgSponsor02} alt="sponsor-logo-06" />
          <PartnerImage src={imgSponsor01} alt="sponsor-logo-04" />
          <PartnerImage src={imgSponsor04} alt="sponsor-logo-05" />
        </LogoSection>
      </PartnerSection>
    </Wrapper>
  );
};
