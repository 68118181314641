export const xIcon = require('./x-mark.png');
export const imgLogoBlack = require('./logo-black.png');
export const imgLogoBlackWide = 'https://firebasestorage.googleapis.com/v0/b/norway-92d0f.appspot.com/o/norway_wood_identity_%20horizontal.svg?alt=media';

export const imgAbout = require('./about.jpg');
export const logoStory = require('./logo-story1.jpg');

export const imgSponsor01 = require('./logo_1.jpg');
export const imgSponsor02 = require('./logo_2.jpg');
export const imgSponsor03 = require('./logo_3.jpg');
export const imgSponsor04 = require('./logo_4.jpg');
export const imgSponsor05 = require('./logo_5.jpg');
export const imgSponsor06 = require('./logo_6.jpg');