import React from 'react';
import styled from 'styled-components';
import { device } from 'utils/constants';
import { ProjectDetailInfo } from './types';
import { isEmpty as _isEmpty } from 'lodash';

interface ProjectInfoProps {
  project: ProjectDetailInfo,
}

const Wrapper = styled.div``;

const Info = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 12px;
  font-size: 14px;

  &:last-of-type {
    padding-bottom: 0;
  }

  @media ${device.desktop} {
    padding-bottom: 12px;
  }
`;

const Title = styled.p`
  padding: 0 8px;
  border-right: 1px solid #000;
`;

const Contents = styled(Title)`
  padding-right: 0;
  border: none;
`;

const ProjectInfoProps: React.FC<ProjectInfoProps> = ({ project }) => {
  if (project == null) {
    return null;
  }

  return (
    <Wrapper>
      <Info>
        <Title>Project Name</Title>
        <Contents>{project.name || ''}</Contents>
      </Info>
      <Info>
        <Title>Space Type</Title>
        <Contents>{project.type || ''}</Contents>
      </Info>
      <Info>
        <Title>Location</Title>
        <Contents>{project.location || ''}</Contents>
      </Info>
      <Info>
        <Title>Area</Title>
        <Contents>{project.area || ''}</Contents>
      </Info>
      {
        !_isEmpty(project.client) && (
          <Info>
          <Title>Client</Title>
          <Contents>{project.client}</Contents>
        </Info>
        )
      }
    </Wrapper>
  );
};

export default ProjectInfoProps;
